import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import useIsSsr from '../../hooks/useIsSsr'

import { BackToTopContainer, Arrow } from './backToTopStyles'

const BackToTop = ({ to }) => {
    const isSsr = useIsSsr()

    const [visible, setVisible] = useState(false)

    useScrollPosition(
        ({ currPos }) => {
            const viewHeight = isSsr ? null : document.documentElement.clientHeight * 0.65
            const position = Math.abs(currPos.y)

            if (!visible && position > viewHeight) {
                setVisible(true)
            }

            if (visible && position < viewHeight) {
                setVisible(false)
            }
        },
        [visible]
    )

    if (isSsr) return null

    return (
        <BackToTopContainer to={to} className={classnames({ hidden: !visible })}>
            <Arrow />
        </BackToTopContainer>
    )
}

BackToTop.propTypes = {
    to: PropTypes.string.isRequired
}

export default BackToTop
