import styled from '@emotion/styled'

export const FooterContainer = styled.footer`
    font-size: 0.75rem;
    font-family: 'Full Sans LC 70', var(--font-family-sans-serif);
    background-color: white;
    border-top: 1px solid var(--gold);
    margin: 0 -1.25rem -1.25rem -1.25rem;
    padding: 0.75rem 1.25rem;
`

export const FooterParagraph = styled.p`
    margin: 0;
`
