import React, { memo } from 'react'
import PropTypes from 'prop-types'

import { IconContainer } from './serviceIconsStyles'

import Bed from '../../images/svg/bed.svg'
import SingleBed from '../../images/svg/bed-single.svg'
import Bunk from '../../images/svg/bunk.svg'
import Hairdryer from '../../images/svg/hairdryer.svg'
import Shower from '../../images/svg/shower.svg'
import Towel from '../../images/svg/towel.svg'
import Television from '../../images/svg/television.svg'
import Wifi from '../../images/svg/wifi.svg'

const ServiceIcons = memo(({ options, ...props }) => (
    <IconContainer {...props}>
        {options.indexOf('bed') !== -1 && <Bed />}
        {options.indexOf('singleBed') !== -1 && <SingleBed />}
        {options.indexOf('bunk') !== -1 && <Bunk />}
        {options.indexOf('hairDryer') !== -1 && <Hairdryer />}
        {options.indexOf('shower') !== -1 && <Shower />}
        {options.indexOf('towel') !== -1 && <Towel />}
        {options.indexOf('television') !== -1 && <Television />}
        {options.indexOf('wifi') !== -1 && <Wifi />}
    </IconContainer>
))

ServiceIcons.propTypes = {
    options: PropTypes.array.isRequired,
}

export default ServiceIcons
