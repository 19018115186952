import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import { FooterContainer, FooterParagraph } from './footerStyles'
import { Container, Row, Col } from 'react-bootstrap'
import FooterNav from '../footer-nav/FooterNav'

const Footer = () => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        mainTitle
                        author
                    }
                }
            }
        `
    )

    return (
        <FooterContainer>
            <Container fluid>
                <Row className="align-items-center flex-column flex-md-row">
                    <Col xs="12" md={4} className="text-center text-md-left mb-3 mb-md-0">
                        <FooterParagraph id="copyright">
                            copyright &copy; {site.siteMetadata?.mainTitle}. Minden jog fenntartva.
                        </FooterParagraph>
                    </Col>
                    <Col className="footer-links mb-3 mb-md-0">
                        <FooterNav />
                    </Col>
                    <Col xs={12} md="4">
                        <FooterParagraph id="creator" className="text-center text-md-left mb-3 mb-md-0">
                            Készítette: {site.siteMetadata?.author}
                        </FooterParagraph>
                    </Col>
                </Row>
            </Container>
        </FooterContainer>
    )
}

export default Footer
