import styled from '@emotion/styled'
import Button from 'react-bootstrap/Button'

export const NavLinkButton = styled(Button)`
    font-size: 0.75rem;
    color: var(--black);
    text-decoration: underline;
    background-color: transparent;
    transition: all 250ms ease-out;
    border-radius: 0;

    &:hover {
        background-color: var(--gray);
        color: var(--black);
    }
`
