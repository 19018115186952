import React from 'react'

import Nav from 'react-bootstrap/Nav'
import { NavLinkButton } from './footerNavStyles'

const FooterNav = () => {
    return (
        <Nav className="justify-content-center">
            <Nav.Item className="mr-2">
                <NavLinkButton variant="link">Felhasználási feltételek</NavLinkButton>
            </Nav.Item>

            <Nav.Item className="ml-2">
                <NavLinkButton variant="link">Adatvédelmi irányelvek</NavLinkButton>
            </Nav.Item>
        </Nav>
    )
}

export default FooterNav
