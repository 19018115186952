import styled from '@emotion/styled'

import Col from 'react-bootstrap/Col'

export const IconContainer = styled(Col)`
    text-align: center;

    svg {
        height: 2.1875rem; /* 35px */
        fill: var(--brown);
        margin-right: 0.625rem; /* 10px */

        &:last-of-type {
            margin-right: 0;
        }

        @media (max-width: 375px) {
            margin-bottom: 0.625rem;
        }
    }
`
