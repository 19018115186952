import styled from '@emotion/styled'

import Form from 'react-bootstrap/Form'

export const FormContainer = styled(Form)`
    background-color: white;
    padding: 3.75rem; /* 60px */
    box-shadow: var(--box-shadow);

    @media (max-width: 375px) {
        padding: 1.25rem;
    }
`

export const FormCheck = styled(Form.Check)`
    text-size: 1rem; /* 16px */
    margin-bottom: 2.5rem; /* 40px */
`
