import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from "react-bootstrap/Form"
import FormInput from '../form-input/FormInput'
import { FormCheck } from "../contact-form/contactFormStyles"

const ContactPersonalInfo = memo(({ changeHandler, data, title, address, isReservation }) => {
    const [isFirm, setIsFirm] = useState(false)

    const handleCheckChange = () => setIsFirm(!isFirm)
    
    return (
        <>
            { !isEmpty(title) && (
                <Row>
                    <Col>
                        <p className="h4">{title}</p>
                    </Col>
                </Row>
            ) }

            { isReservation && (
                <Row>

                    <Col>

                        <Form.Group controlId="is-firm">

                            <FormCheck
                                id="is-firm"
                                label={`cégként foglalok`}
                                checked={isFirm}
                                onChange={handleCheckChange}
                            />

                        </Form.Group>

                    </Col>

                </Row>
            )}

            {isFirm && (
                <Row>
                    <Col sm="12" md="12">
                        <FormInput label="Cégnév *" name="firm" value={data.firm} onChange={changeHandler} required />
                    </Col>
                </Row>
            )}

            <Row>
                <Col sm="12" md="6">
                    <FormInput label="Vezetéknév *" name="lastname" value={data.lastname} onChange={changeHandler} required />
                </Col>
                <Col sm="12" md="6">
                    <FormInput label="Keresztnév *" name="firstname" value={data.firstname} onChange={changeHandler} required />
                </Col>
            </Row>

            <Row>
                <Col sm="12" md="6">
                    <FormInput label="Email *" name="email" value={data.email} onChange={changeHandler} required />
                </Col>
                <Col sm="12" md="6">
                    <FormInput label="Telefon *" name="phone" value={data.phone} onChange={changeHandler} required />
                </Col>
            </Row>

            { isReservation && (
                <Row>
                    <Col sm="12" md="6">
                        <FormInput label={`Adószám${ isFirm ? ' *' : ''}`} name="tax" value={data.tax} onChange={changeHandler} required={isFirm} />
                    </Col>
                </Row>
            ) }

            { address && (
                <Row>
                    <Col>
                        <FormInput label="Számlázási cím *" name="address" value={data.address} onChange={changeHandler} required />
                    </Col>
                </Row>
            ) }
        </>
    )
})

ContactPersonalInfo.propTypes = {
    changeHandler: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    address: PropTypes.bool.isRequired,
}

ContactPersonalInfo.defaultProps = {
    title: '',
    address: false
}

export default ContactPersonalInfo
